<template>
    <div class="outpatient">
        <div class="content">
            <img src="../../assets/goods/menzhenyongyao.jpg" class="menzhenzonghe" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {}
    },
    created () {
    }
}
</script>

<style lang="less" scoped>
    .outpatient {
        .content {
            .menzhenzonghe {
                width: 100%;
                height: 3210px;
            }
        }
    }
</style>